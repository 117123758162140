* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none; /* no underline */
  color: black;
}

/* Checkout Steps */
.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1;
  padding: 1rem;
  font-weight: bold;
}
.checkout-steps > div.active {
  border-top-color: #f08000;
  color: #f08000;
}
